import { createTheme } from "@mui/material/styles";
import { grey } from "@mui/material/colors";

declare module "@emotion/react" {
  export interface Theme {
    palette: {
      primary: {
        main: string;
      };
      secondary: {
        main: string;
      };
      error: {
        main: string;
      };
      common: {
        white: string;
        black: string;
      };
      grey: typeof grey;
    };
  }
}

// Create a theme instance.
export const theme = createTheme({
  palette: {
    primary: {
      main: "#FC6539",
    },
    secondary: {
      main: "#F6F8F7",
    },
    error: {
      main: "#FF4842",
    },
    common: {
      white: "#FFF",
      black: "#000",
    },
    grey,
  },
});
