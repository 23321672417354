/* eslint-disable */
import gql from "graphql-tag";
import * as Urql from "urql";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Raw bytes encoded as bas64 */
  Buffer: any;
  /** ISO 8603 date like '2021-11-04T15:42:29.482Z' */
  DateTime: any;
  /** GraphQL Upload thingy */
  Upload: any;
  /** A URL that might be relative to this API host's domain */
  Url: any;
};

export type DeletePhotoResult = {
  __typename?: "DeletePhotoResult";
  affectedTestResult: TestResultTester;
  deletedPhoto: TestResultPhoto;
};

export type File = {
  __typename?: "File";
  encoding: Scalars["String"];
  filename: Scalars["String"];
  mimetype: Scalars["String"];
};

export type Mutation = {
  __typename?: "Mutation";
  addObjectToTestRun: TestRunInstance;
  deleteTestResultPhoto: DeletePhotoResult;
  markTestRunInstanceAsDeleted: TestRunInstance;
  markTestRunInstanceAsNotDeleted: TestRunInstance;
  shareTestReport?: Maybe<SharedReport>;
  submitNotesToTestRun: TestRun;
  submitTestResult: TestRunInstance;
  submitTestRun: TestRunSubmissionResult;
  updateSiteNotes: Site;
  uploadPhotoForTestResultTester: UploadPhotoResult;
  uploadSyslogForTestrun: UploadSyslogResult;
};

export type MutationAddObjectToTestRunArgs = {
  object: Scalars["ID"];
  testRun: Scalars["ID"];
};

export type MutationDeleteTestResultPhotoArgs = {
  photo: Scalars["ID"];
};

export type MutationMarkTestRunInstanceAsDeletedArgs = {
  testRunInstance: Scalars["ID"];
};

export type MutationMarkTestRunInstanceAsNotDeletedArgs = {
  testRunInstance: Scalars["ID"];
};

export type MutationShareTestReportArgs = {
  testRun: Scalars["ID"];
};

export type MutationSubmitNotesToTestRunArgs = {
  noteActions?: InputMaybe<Scalars["String"]>;
  noteClient?: InputMaybe<Scalars["String"]>;
  noteFollowUp?: InputMaybe<Scalars["String"]>;
  noteSales?: InputMaybe<Scalars["String"]>;
  testRun: Scalars["ID"];
};

export type MutationSubmitTestResultArgs = {
  actionTakenByTester?: InputMaybe<Scalars["Boolean"]>;
  comment?: InputMaybe<Scalars["String"]>;
  includeInReport?: InputMaybe<Scalars["Boolean"]>;
  recommendation?: InputMaybe<Scalars["String"]>;
  result: TesterResult;
  testFailedStatus?: InputMaybe<TestFailedStatus>;
  testInstance: Scalars["ID"];
};

export type MutationSubmitTestRunArgs = {
  testRun: Scalars["ID"];
};

export type MutationUpdateSiteNotesArgs = {
  note: Scalars["String"];
  site: Scalars["ID"];
};

export type MutationUploadPhotoForTestResultTesterArgs = {
  file: Scalars["Upload"];
  testResultTester: Scalars["ID"];
};

export type MutationUploadSyslogForTestrunArgs = {
  file: Scalars["Upload"];
  testRun: Scalars["ID"];
};

export type PhotoParseError = {
  __typename?: "PhotoParseError";
  message: Scalars["String"];
};

export type Query = {
  __typename?: "Query";
  getSharedTestRun?: Maybe<TestRun>;
  getSite?: Maybe<Site>;
  getSites: Array<Site>;
  getTestProtocol?: Maybe<TestRun>;
  outstandingIssues: Array<TestRunInstance>;
  previousTestRuns: Array<TestRun>;
  tasksForSales: Array<SalesTasksPerSite>;
  upcomingTests: Array<TestRun>;
};

export type QueryGetSharedTestRunArgs = {
  sharedId: Scalars["ID"];
};

export type QueryGetSiteArgs = {
  id: Scalars["ID"];
};

export type QueryGetSitesArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetTestProtocolArgs = {
  id: Scalars["ID"];
};

export type QueryOutstandingIssuesArgs = {
  site: Scalars["ID"];
};

export type QueryPreviousTestRunsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  submitted?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryUpcomingTestsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
};

export type SalesTasksPerSite = {
  __typename?: "SalesTasksPerSite";
  site: Site;
  tasks: Array<TestResultTester>;
};

export type SharedReport = {
  __typename?: "SharedReport";
  expiresAt: Scalars["DateTime"];
  id: Scalars["ID"];
  testRun: Scalars["ID"];
};

export type Site = {
  __typename?: "Site";
  address: Scalars["String"];
  id: Scalars["ID"];
  name: Scalars["String"];
  notes: Scalars["String"];
  soc: Scalars["String"];
  testRuns: Array<TestRun>;
  testableObjects: Array<TestableObject>;
};

export type SyslogMappingError = {
  __typename?: "SyslogMappingError";
  message: Scalars["String"];
};

export type SyslogParseError = {
  __typename?: "SyslogParseError";
  message: Scalars["String"];
};

export enum SystemResult {
  Error = "Error",
  Verified = "Verified",
}

export enum TestFailedStatus {
  Critical = "Critical",
  Improvement = "Improvement",
  Warning = "Warning",
}

export enum TestInstanceGroup {
  Central = "Central",
  ControlFunctions = "ControlFunctions",
  Detectors = "Detectors",
  Documentation = "Documentation",
  IndividualDetectors = "IndividualDetectors",
  PostTest = "PostTest",
  PreTest = "PreTest",
  TestedBySystem = "TestedBySystem",
}

export type TestResultPhoto = {
  __typename?: "TestResultPhoto";
  fileUrl: Scalars["Url"];
  filename: Scalars["String"];
  id: Scalars["ID"];
  mimetype: Scalars["String"];
  testResultTester: TestResultTester;
  thumbnailMimetype?: Maybe<Scalars["String"]>;
  thumbnailUrl: Scalars["Url"];
};

export type TestResultSystem = {
  __typename?: "TestResultSystem";
  id: Scalars["ID"];
  instance: TestRunInstance;
  message: Scalars["String"];
  sourceSyslog: TestRunSyslog;
  state: SystemResult;
  syslogMessageId?: Maybe<Scalars["Int"]>;
  updatedAt: Scalars["DateTime"];
};

export type TestResultTester = {
  __typename?: "TestResultTester";
  actionTakenByTester?: Maybe<Scalars["DateTime"]>;
  comment?: Maybe<Scalars["String"]>;
  followedUpBySales?: Maybe<Scalars["DateTime"]>;
  id: Scalars["ID"];
  includeInReport: Scalars["Boolean"];
  photos: Array<TestResultPhoto>;
  recommendation?: Maybe<Scalars["String"]>;
  state: TesterResult;
  testFailedStatus?: Maybe<TestFailedStatus>;
  updatedAt: Scalars["DateTime"];
};

export type TestRun = {
  __typename?: "TestRun";
  date: Scalars["DateTime"];
  id: Scalars["ID"];
  instances: Array<TestRunInstance>;
  noteActions: Scalars["String"];
  noteClient: Scalars["String"];
  noteFollowUp: Scalars["String"];
  noteSales: Scalars["String"];
  site: Site;
  submittedOn?: Maybe<Scalars["DateTime"]>;
  syslogs: Array<TestRunSyslog>;
  testedBy: Scalars["String"];
};

export type TestRunInstance = {
  __typename?: "TestRunInstance";
  deletedAt?: Maybe<Scalars["DateTime"]>;
  group: TestInstanceGroup;
  id: Scalars["ID"];
  name: Scalars["String"];
  object?: Maybe<TestableObject>;
  resultSystem?: Maybe<TestResultSystem>;
  resultTester?: Maybe<TestResultTester>;
};

export type TestRunSubmissionAlreadyDone = {
  __typename?: "TestRunSubmissionAlreadyDone";
  message?: Maybe<Scalars["String"]>;
};

export type TestRunSubmissionError = TestRunSubmissionAlreadyDone | TestRunSubmissionIncomplete;

export type TestRunSubmissionIncomplete = {
  __typename?: "TestRunSubmissionIncomplete";
  message?: Maybe<Scalars["String"]>;
  /** IDs of missing test instances */
  missing: Array<TestRunInstance>;
};

export type TestRunSubmissionResult = {
  __typename?: "TestRunSubmissionResult";
  data: TestRun;
  error?: Maybe<TestRunSubmissionError>;
};

export type TestRunSyslog = {
  __typename?: "TestRunSyslog";
  file: Scalars["Buffer"];
  filename: Scalars["String"];
  id: Scalars["ID"];
  results: Array<TestResultSystem>;
};

export type TestableObject = {
  __typename?: "TestableObject";
  cardAddress?: Maybe<Scalars["Int"]>;
  centralAddress?: Maybe<Scalars["Int"]>;
  description1: Scalars["String"];
  description2: Scalars["String"];
  id: Scalars["ID"];
  logicalAddress?: Maybe<Scalars["Int"]>;
  loopAddress?: Maybe<Scalars["Int"]>;
  outputAddress?: Maybe<Scalars["Int"]>;
  physicalAddress?: Maybe<Scalars["Int"]>;
  site: Site;
  type: TestableObjectType;
  zone?: Maybe<Scalars["Int"]>;
};

export enum TestableObjectType {
  Bell = "Bell",
  Documentation = "Documentation",
  Door = "Door",
  Heat = "Heat",
  Io = "IO",
  Mcp = "MCP",
  Other = "Other",
  Smoke = "Smoke",
  SmokeHeat = "SmokeHeat",
  UnknownSensor = "UnknownSensor",
  Ventilation = "Ventilation",
}

export enum TesterResult {
  NotWorking = "NotWorking",
  Skipped = "Skipped",
  Working = "Working",
}

export type UploadError = {
  __typename?: "UploadError";
  message: Scalars["String"];
};

export type UploadPhotoError = PhotoParseError | UploadError;

export type UploadPhotoResult = {
  __typename?: "UploadPhotoResult";
  error?: Maybe<UploadPhotoError>;
  photo?: Maybe<TestResultPhoto>;
  testResult: TestResultTester;
};

export type UploadSyslogError = SyslogMappingError | SyslogParseError | UploadError;

export type UploadSyslogResult = {
  __typename?: "UploadSyslogResult";
  createdEntries: Array<TestResultSystem>;
  data?: Maybe<TestRunSyslog>;
  error?: Maybe<UploadSyslogError>;
};

export type ObjectsInSiteQueryVariables = Exact<{
  site: Scalars["ID"];
}>;

export type ObjectsInSiteQuery = {
  __typename?: "Query";
  getSite?: {
    __typename?: "Site";
    id: string;
    testableObjects: Array<{
      __typename?: "TestableObject";
      id: string;
      zone?: number | null;
      cardAddress?: number | null;
      loopAddress?: number | null;
      outputAddress?: number | null;
      physicalAddress?: number | null;
      logicalAddress?: number | null;
      type: TestableObjectType;
      description1: string;
      description2: string;
    }>;
  } | null;
};

export type UpdateSiteNotesMutationVariables = Exact<{
  site: Scalars["ID"];
  newNotes: Scalars["String"];
}>;

export type UpdateSiteNotesMutation = {
  __typename?: "Mutation";
  updateSiteNotes: { __typename?: "Site"; id: string; notes: string };
};

export type AddObjectToTestRunMutationVariables = Exact<{
  testRunId: Scalars["ID"];
  objectId: Scalars["ID"];
}>;

export type AddObjectToTestRunMutation = {
  __typename?: "Mutation";
  addObjectToTestRun: {
    __typename?: "TestRunInstance";
    id: string;
    object?: {
      __typename?: "TestableObject";
      id: string;
      type: TestableObjectType;
      description1: string;
      description2: string;
      zone?: number | null;
      physicalAddress?: number | null;
      logicalAddress?: number | null;
      cardAddress?: number | null;
      outputAddress?: number | null;
    } | null;
    resultTester?: {
      __typename?: "TestResultTester";
      id: string;
      state: TesterResult;
      comment?: string | null;
      recommendation?: string | null;
      testFailedStatus?: TestFailedStatus | null;
      includeInReport: boolean;
      photos: Array<{ __typename?: "TestResultPhoto"; id: string; thumbnailUrl: any }>;
    } | null;
  };
};

export type UploadSyslogMutationVariables = Exact<{
  testRun: Scalars["ID"];
  file: Scalars["Upload"];
}>;

export type UploadSyslogMutation = {
  __typename?: "Mutation";
  uploadSyslogForTestrun: {
    __typename?: "UploadSyslogResult";
    data?: { __typename?: "TestRunSyslog"; id: string; filename: string } | null;
    createdEntries: Array<{
      __typename?: "TestResultSystem";
      id: string;
      state: SystemResult;
      message: string;
      instance: {
        __typename?: "TestRunInstance";
        id: string;
        object?: {
          __typename?: "TestableObject";
          id: string;
          type: TestableObjectType;
          description1: string;
          description2: string;
          zone?: number | null;
          physicalAddress?: number | null;
          logicalAddress?: number | null;
          cardAddress?: number | null;
          outputAddress?: number | null;
        } | null;
      };
    }>;
    error?:
      | { __typename?: "SyslogMappingError"; message: string }
      | { __typename?: "SyslogParseError"; message: string }
      | { __typename?: "UploadError"; message: string }
      | null;
  };
};

export type ShareTestReportMutationVariables = Exact<{
  testRun: Scalars["ID"];
}>;

export type ShareTestReportMutation = {
  __typename?: "Mutation";
  shareTestReport?: { __typename?: "SharedReport"; id: string; expiresAt: any } | null;
};

export type DeletePhotoMutationVariables = Exact<{
  photoId: Scalars["ID"];
}>;

export type DeletePhotoMutation = {
  __typename?: "Mutation";
  deleteTestResultPhoto: {
    __typename?: "DeletePhotoResult";
    affectedTestResult: {
      __typename?: "TestResultTester";
      id: string;
      photos: Array<{ __typename?: "TestResultPhoto"; thumbnailUrl: any; id: string }>;
    };
  };
};

export type UploadPhotoMutationVariables = Exact<{
  testResultTester: Scalars["ID"];
  file: Scalars["Upload"];
}>;

export type UploadPhotoMutation = {
  __typename?: "Mutation";
  uploadPhotoForTestResultTester: {
    __typename?: "UploadPhotoResult";
    testResult: {
      __typename?: "TestResultTester";
      id: string;
      photos: Array<{
        __typename?: "TestResultPhoto";
        id: string;
        filename: string;
        fileUrl: any;
        mimetype: string;
        thumbnailUrl: any;
        thumbnailMimetype?: string | null;
      }>;
    };
    error?:
      | { __typename?: "PhotoParseError"; message: string }
      | { __typename?: "UploadError"; message: string }
      | null;
  };
};

export type DeleteTestMutationVariables = Exact<{
  testRunInstance: Scalars["ID"];
}>;

export type DeleteTestMutation = {
  __typename?: "Mutation";
  markTestRunInstanceAsDeleted: {
    __typename?: "TestRunInstance";
    id: string;
    deletedAt?: any | null;
  };
};

export type UnDeleteTestMutationVariables = Exact<{
  testRunInstance: Scalars["ID"];
}>;

export type UnDeleteTestMutation = {
  __typename?: "Mutation";
  markTestRunInstanceAsNotDeleted: {
    __typename?: "TestRunInstance";
    id: string;
    deletedAt?: any | null;
  };
};

export type SalesTaskFragment = {
  __typename?: "TestResultTester";
  id: string;
  state: TesterResult;
  testFailedStatus?: TestFailedStatus | null;
  includeInReport: boolean;
  comment?: string | null;
  recommendation?: string | null;
  actionTakenByTester?: any | null;
  followedUpBySales?: any | null;
};

export type SalesQueryVariables = Exact<{ [key: string]: never }>;

export type SalesQuery = {
  __typename?: "Query";
  getSites: Array<{
    __typename?: "Site";
    id: string;
    name: string;
    testRuns: Array<{
      __typename?: "TestRun";
      id: string;
      date: any;
      testedBy: string;
      noteActions: string;
      noteSales: string;
      noteFollowUp: string;
      noteClient: string;
      instances: Array<{
        __typename?: "TestRunInstance";
        id: string;
        name: string;
        resultTester?: {
          __typename?: "TestResultTester";
          id: string;
          state: TesterResult;
          testFailedStatus?: TestFailedStatus | null;
          includeInReport: boolean;
          comment?: string | null;
          recommendation?: string | null;
          actionTakenByTester?: any | null;
          followedUpBySales?: any | null;
        } | null;
      }>;
    }>;
  }>;
};

export type UpcomingTestsQueryVariables = Exact<{ [key: string]: never }>;

export type UpcomingTestsQuery = {
  __typename?: "Query";
  upcomingTests: Array<{
    __typename?: "TestRun";
    id: string;
    date: any;
    submittedOn?: any | null;
    site: { __typename?: "Site"; id: string; name: string };
  }>;
};

export type PreviousTestRunsQueryVariables = Exact<{ [key: string]: never }>;

export type PreviousTestRunsQuery = {
  __typename?: "Query";
  previousTestRuns: Array<{
    __typename?: "TestRun";
    id: string;
    date: any;
    submittedOn?: any | null;
    site: { __typename?: "Site"; id: string; name: string };
  }>;
};

export type SiteQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type SiteQuery = {
  __typename?: "Query";
  getSite?: {
    __typename?: "Site";
    id: string;
    name: string;
    address: string;
    testableObjects: Array<{
      __typename?: "TestableObject";
      id: string;
      type: TestableObjectType;
      description1: string;
      description2: string;
      centralAddress?: number | null;
      cardAddress?: number | null;
      loopAddress?: number | null;
      outputAddress?: number | null;
      physicalAddress?: number | null;
      logicalAddress?: number | null;
    }>;
    testRuns: Array<{ __typename?: "TestRun"; id: string; date: any; submittedOn?: any | null }>;
  } | null;
};

export type OutstandingIssuesQueryVariables = Exact<{
  site: Scalars["ID"];
}>;

export type OutstandingIssuesQuery = {
  __typename?: "Query";
  outstandingIssues: Array<{
    __typename?: "TestRunInstance";
    id: string;
    name: string;
    group: TestInstanceGroup;
    object?: {
      __typename?: "TestableObject";
      id: string;
      type: TestableObjectType;
      description1: string;
      description2: string;
      centralAddress?: number | null;
      cardAddress?: number | null;
      loopAddress?: number | null;
      outputAddress?: number | null;
      physicalAddress?: number | null;
      logicalAddress?: number | null;
    } | null;
    resultTester?: {
      __typename?: "TestResultTester";
      id: string;
      state: TesterResult;
      comment?: string | null;
      recommendation?: string | null;
      testFailedStatus?: TestFailedStatus | null;
    } | null;
    resultSystem?: { __typename?: "TestResultSystem"; id: string; state: SystemResult } | null;
  }>;
};

export type GetTestNotesQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetTestNotesQuery = {
  __typename?: "Query";
  getTestProtocol?: {
    __typename?: "TestRun";
    id: string;
    noteSales: string;
    noteFollowUp: string;
    noteClient: string;
    noteActions: string;
  } | null;
};

export type ObjectFragment = {
  __typename?: "TestableObject";
  id: string;
  type: TestableObjectType;
  description1: string;
  description2: string;
  zone?: number | null;
  physicalAddress?: number | null;
  logicalAddress?: number | null;
  cardAddress?: number | null;
  outputAddress?: number | null;
};

export type PhotoFragment = {
  __typename?: "TestResultPhoto";
  id: string;
  filename: string;
  fileUrl: any;
  mimetype: string;
  thumbnailUrl: any;
  thumbnailMimetype?: string | null;
};

export type ResultTesterFragment = {
  __typename?: "TestResultTester";
  id: string;
  state: TesterResult;
  comment?: string | null;
  updatedAt: any;
  recommendation?: string | null;
  testFailedStatus?: TestFailedStatus | null;
  includeInReport: boolean;
  actionTakenByTester?: any | null;
  photos: Array<{
    __typename?: "TestResultPhoto";
    id: string;
    filename: string;
    fileUrl: any;
    mimetype: string;
    thumbnailUrl: any;
    thumbnailMimetype?: string | null;
  }>;
};

export type TestInstanceFragment = {
  __typename?: "TestRunInstance";
  id: string;
  name: string;
  group: TestInstanceGroup;
  deletedAt?: any | null;
  object?: {
    __typename?: "TestableObject";
    id: string;
    type: TestableObjectType;
    description1: string;
    description2: string;
    zone?: number | null;
    physicalAddress?: number | null;
    logicalAddress?: number | null;
    cardAddress?: number | null;
    outputAddress?: number | null;
  } | null;
  resultSystem?: {
    __typename?: "TestResultSystem";
    id: string;
    updatedAt: any;
    state: SystemResult;
    message: string;
  } | null;
  resultTester?: {
    __typename?: "TestResultTester";
    id: string;
    state: TesterResult;
    comment?: string | null;
    updatedAt: any;
    recommendation?: string | null;
    testFailedStatus?: TestFailedStatus | null;
    includeInReport: boolean;
    actionTakenByTester?: any | null;
    photos: Array<{
      __typename?: "TestResultPhoto";
      id: string;
      filename: string;
      fileUrl: any;
      mimetype: string;
      thumbnailUrl: any;
      thumbnailMimetype?: string | null;
    }>;
  } | null;
};

export type TestProtocolFragment = {
  __typename?: "TestRun";
  id: string;
  date: any;
  noteSales: string;
  noteFollowUp: string;
  noteClient: string;
  noteActions: string;
  submittedOn?: any | null;
  testedBy: string;
  site: {
    __typename?: "Site";
    id: string;
    name: string;
    address: string;
    soc: string;
    notes: string;
  };
  syslogs: Array<{ __typename?: "TestRunSyslog"; id: string; filename: string }>;
  instances: Array<{
    __typename?: "TestRunInstance";
    id: string;
    name: string;
    group: TestInstanceGroup;
    deletedAt?: any | null;
    object?: {
      __typename?: "TestableObject";
      id: string;
      type: TestableObjectType;
      description1: string;
      description2: string;
      zone?: number | null;
      physicalAddress?: number | null;
      logicalAddress?: number | null;
      cardAddress?: number | null;
      outputAddress?: number | null;
    } | null;
    resultSystem?: {
      __typename?: "TestResultSystem";
      id: string;
      updatedAt: any;
      state: SystemResult;
      message: string;
    } | null;
    resultTester?: {
      __typename?: "TestResultTester";
      id: string;
      state: TesterResult;
      comment?: string | null;
      updatedAt: any;
      recommendation?: string | null;
      testFailedStatus?: TestFailedStatus | null;
      includeInReport: boolean;
      actionTakenByTester?: any | null;
      photos: Array<{
        __typename?: "TestResultPhoto";
        id: string;
        filename: string;
        fileUrl: any;
        mimetype: string;
        thumbnailUrl: any;
        thumbnailMimetype?: string | null;
      }>;
    } | null;
  }>;
};

export type GetTestProtocolQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetTestProtocolQuery = {
  __typename?: "Query";
  getTestProtocol?: {
    __typename?: "TestRun";
    id: string;
    date: any;
    noteSales: string;
    noteFollowUp: string;
    noteClient: string;
    noteActions: string;
    submittedOn?: any | null;
    testedBy: string;
    site: {
      __typename?: "Site";
      id: string;
      name: string;
      address: string;
      soc: string;
      notes: string;
    };
    syslogs: Array<{ __typename?: "TestRunSyslog"; id: string; filename: string }>;
    instances: Array<{
      __typename?: "TestRunInstance";
      id: string;
      name: string;
      group: TestInstanceGroup;
      deletedAt?: any | null;
      object?: {
        __typename?: "TestableObject";
        id: string;
        type: TestableObjectType;
        description1: string;
        description2: string;
        zone?: number | null;
        physicalAddress?: number | null;
        logicalAddress?: number | null;
        cardAddress?: number | null;
        outputAddress?: number | null;
      } | null;
      resultSystem?: {
        __typename?: "TestResultSystem";
        id: string;
        updatedAt: any;
        state: SystemResult;
        message: string;
      } | null;
      resultTester?: {
        __typename?: "TestResultTester";
        id: string;
        state: TesterResult;
        comment?: string | null;
        updatedAt: any;
        recommendation?: string | null;
        testFailedStatus?: TestFailedStatus | null;
        includeInReport: boolean;
        actionTakenByTester?: any | null;
        photos: Array<{
          __typename?: "TestResultPhoto";
          id: string;
          filename: string;
          fileUrl: any;
          mimetype: string;
          thumbnailUrl: any;
          thumbnailMimetype?: string | null;
        }>;
      } | null;
    }>;
  } | null;
};

export type GetSharedReportQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetSharedReportQuery = {
  __typename?: "Query";
  getSharedTestRun?: {
    __typename?: "TestRun";
    id: string;
    date: any;
    noteSales: string;
    noteFollowUp: string;
    noteClient: string;
    noteActions: string;
    submittedOn?: any | null;
    testedBy: string;
    site: {
      __typename?: "Site";
      id: string;
      name: string;
      address: string;
      soc: string;
      notes: string;
    };
    syslogs: Array<{ __typename?: "TestRunSyslog"; id: string; filename: string }>;
    instances: Array<{
      __typename?: "TestRunInstance";
      id: string;
      name: string;
      group: TestInstanceGroup;
      deletedAt?: any | null;
      object?: {
        __typename?: "TestableObject";
        id: string;
        type: TestableObjectType;
        description1: string;
        description2: string;
        zone?: number | null;
        physicalAddress?: number | null;
        logicalAddress?: number | null;
        cardAddress?: number | null;
        outputAddress?: number | null;
      } | null;
      resultSystem?: {
        __typename?: "TestResultSystem";
        id: string;
        updatedAt: any;
        state: SystemResult;
        message: string;
      } | null;
      resultTester?: {
        __typename?: "TestResultTester";
        id: string;
        state: TesterResult;
        comment?: string | null;
        updatedAt: any;
        recommendation?: string | null;
        testFailedStatus?: TestFailedStatus | null;
        includeInReport: boolean;
        actionTakenByTester?: any | null;
        photos: Array<{
          __typename?: "TestResultPhoto";
          id: string;
          filename: string;
          fileUrl: any;
          mimetype: string;
          thumbnailUrl: any;
          thumbnailMimetype?: string | null;
        }>;
      } | null;
    }>;
  } | null;
};

export type SubmitNotesToTestRunMutationVariables = Exact<{
  testRun: Scalars["ID"];
  noteSales?: InputMaybe<Scalars["String"]>;
  noteFollowUp?: InputMaybe<Scalars["String"]>;
  noteClient?: InputMaybe<Scalars["String"]>;
  noteActions?: InputMaybe<Scalars["String"]>;
}>;

export type SubmitNotesToTestRunMutation = {
  __typename?: "Mutation";
  submitNotesToTestRun: {
    __typename?: "TestRun";
    id: string;
    noteSales: string;
    noteFollowUp: string;
    noteClient: string;
    noteActions: string;
  };
};

export type SubmitTestRunMutationVariables = Exact<{
  testRun: Scalars["ID"];
}>;

export type SubmitTestRunMutation = {
  __typename?: "Mutation";
  submitTestRun: {
    __typename: "TestRunSubmissionResult";
    data: { __typename?: "TestRun"; id: string };
    error?:
      | { __typename?: "TestRunSubmissionAlreadyDone"; message?: string | null }
      | {
          __typename?: "TestRunSubmissionIncomplete";
          message?: string | null;
          missing: Array<{ __typename?: "TestRunInstance"; id: string }>;
        }
      | null;
  };
};

export type SubmitTestResultMutationVariables = Exact<{
  result: TesterResult;
  testInstance: Scalars["ID"];
  comment?: InputMaybe<Scalars["String"]>;
  recommendation?: InputMaybe<Scalars["String"]>;
  testFailedStatus?: InputMaybe<TestFailedStatus>;
  includeInReport?: InputMaybe<Scalars["Boolean"]>;
  actionTakenByTester?: InputMaybe<Scalars["Boolean"]>;
}>;

export type SubmitTestResultMutation = {
  __typename?: "Mutation";
  submitTestResult: {
    __typename?: "TestRunInstance";
    id: string;
    resultTester?: {
      __typename?: "TestResultTester";
      id: string;
      state: TesterResult;
      comment?: string | null;
      recommendation?: string | null;
      testFailedStatus?: TestFailedStatus | null;
      includeInReport: boolean;
      actionTakenByTester?: any | null;
      photos: Array<{ __typename?: "TestResultPhoto"; id: string; thumbnailUrl: any }>;
    } | null;
  };
};

export const SalesTaskFragmentDoc = gql`
  fragment SalesTask on TestResultTester {
    id
    state
    testFailedStatus
    includeInReport
    comment
    recommendation
    actionTakenByTester
    followedUpBySales
  }
`;
export const ObjectFragmentDoc = gql`
  fragment Object on TestableObject {
    id
    type
    description1
    description2
    zone
    physicalAddress
    logicalAddress
    cardAddress
    outputAddress
  }
`;
export const PhotoFragmentDoc = gql`
  fragment Photo on TestResultPhoto {
    id
    filename
    fileUrl
    mimetype
    thumbnailUrl
    thumbnailMimetype
  }
`;
export const ResultTesterFragmentDoc = gql`
  fragment ResultTester on TestResultTester {
    id
    state
    comment
    updatedAt
    recommendation
    testFailedStatus
    includeInReport
    actionTakenByTester
    photos {
      ...Photo
    }
  }
  ${PhotoFragmentDoc}
`;
export const TestInstanceFragmentDoc = gql`
  fragment TestInstance on TestRunInstance {
    id
    name
    group
    deletedAt
    object {
      ...Object
    }
    resultSystem {
      id
      updatedAt
      state
      message
    }
    resultTester {
      ...ResultTester
    }
  }
  ${ObjectFragmentDoc}
  ${ResultTesterFragmentDoc}
`;
export const TestProtocolFragmentDoc = gql`
  fragment TestProtocol on TestRun {
    id
    date
    site {
      id
      name
      address
      soc
      notes
    }
    noteSales
    noteFollowUp
    noteClient
    noteActions
    submittedOn
    testedBy
    syslogs {
      id
      filename
    }
    instances {
      ...TestInstance
    }
  }
  ${TestInstanceFragmentDoc}
`;
export const ObjectsInSiteDocument = gql`
  query ObjectsInSite($site: ID!) {
    getSite(id: $site) {
      id
      testableObjects {
        id
        zone
        cardAddress
        loopAddress
        outputAddress
        physicalAddress
        logicalAddress
        type
        description1
        description2
      }
    }
  }
`;

export function useObjectsInSiteQuery(
  options: Omit<Urql.UseQueryArgs<ObjectsInSiteQueryVariables>, "query">,
) {
  return Urql.useQuery<ObjectsInSiteQuery>({ query: ObjectsInSiteDocument, ...options });
}
export const UpdateSiteNotesDocument = gql`
  mutation UpdateSiteNotes($site: ID!, $newNotes: String!) {
    updateSiteNotes(site: $site, note: $newNotes) {
      id
      notes
    }
  }
`;

export function useUpdateSiteNotesMutation() {
  return Urql.useMutation<UpdateSiteNotesMutation, UpdateSiteNotesMutationVariables>(
    UpdateSiteNotesDocument,
  );
}
export const AddObjectToTestRunDocument = gql`
  mutation AddObjectToTestRun($testRunId: ID!, $objectId: ID!) {
    addObjectToTestRun(testRun: $testRunId, object: $objectId) {
      id
      object {
        id
        type
        description1
        description2
        zone
        physicalAddress
        logicalAddress
        cardAddress
        outputAddress
      }
      resultTester {
        id
        state
        comment
        recommendation
        testFailedStatus
        includeInReport
        photos {
          id
          thumbnailUrl
        }
      }
    }
  }
`;

export function useAddObjectToTestRunMutation() {
  return Urql.useMutation<AddObjectToTestRunMutation, AddObjectToTestRunMutationVariables>(
    AddObjectToTestRunDocument,
  );
}
export const UploadSyslogDocument = gql`
  mutation UploadSyslog($testRun: ID!, $file: Upload!) {
    uploadSyslogForTestrun(testRun: $testRun, file: $file) {
      data {
        id
        filename
      }
      createdEntries {
        id
        state
        message
        instance {
          id
          object {
            id
            type
            description1
            description2
            zone
            physicalAddress
            logicalAddress
            cardAddress
            outputAddress
          }
        }
      }
      error {
        ... on UploadError {
          message
        }
        ... on SyslogParseError {
          message
        }
        ... on SyslogMappingError {
          message
        }
      }
    }
  }
`;

export function useUploadSyslogMutation() {
  return Urql.useMutation<UploadSyslogMutation, UploadSyslogMutationVariables>(
    UploadSyslogDocument,
  );
}
export const ShareTestReportDocument = gql`
  mutation ShareTestReport($testRun: ID!) {
    shareTestReport(testRun: $testRun) {
      id
      expiresAt
    }
  }
`;

export function useShareTestReportMutation() {
  return Urql.useMutation<ShareTestReportMutation, ShareTestReportMutationVariables>(
    ShareTestReportDocument,
  );
}
export const DeletePhotoDocument = gql`
  mutation DeletePhoto($photoId: ID!) {
    deleteTestResultPhoto(photo: $photoId) {
      affectedTestResult {
        id
        photos {
          thumbnailUrl
          id
        }
      }
    }
  }
`;

export function useDeletePhotoMutation() {
  return Urql.useMutation<DeletePhotoMutation, DeletePhotoMutationVariables>(DeletePhotoDocument);
}
export const UploadPhotoDocument = gql`
  mutation UploadPhoto($testResultTester: ID!, $file: Upload!) {
    uploadPhotoForTestResultTester(testResultTester: $testResultTester, file: $file) {
      testResult {
        id
        photos {
          id
          filename
          fileUrl
          mimetype
          thumbnailUrl
          thumbnailMimetype
        }
      }
      error {
        ... on UploadError {
          message
        }
        ... on PhotoParseError {
          message
        }
      }
    }
  }
`;

export function useUploadPhotoMutation() {
  return Urql.useMutation<UploadPhotoMutation, UploadPhotoMutationVariables>(UploadPhotoDocument);
}
export const DeleteTestDocument = gql`
  mutation DeleteTest($testRunInstance: ID!) {
    markTestRunInstanceAsDeleted(testRunInstance: $testRunInstance) {
      id
      deletedAt
    }
  }
`;

export function useDeleteTestMutation() {
  return Urql.useMutation<DeleteTestMutation, DeleteTestMutationVariables>(DeleteTestDocument);
}
export const UnDeleteTestDocument = gql`
  mutation UnDeleteTest($testRunInstance: ID!) {
    markTestRunInstanceAsNotDeleted(testRunInstance: $testRunInstance) {
      id
      deletedAt
    }
  }
`;

export function useUnDeleteTestMutation() {
  return Urql.useMutation<UnDeleteTestMutation, UnDeleteTestMutationVariables>(
    UnDeleteTestDocument,
  );
}
export const SalesDocument = gql`
  query Sales {
    getSites {
      id
      name
      testRuns {
        id
        date
        testedBy
        noteActions
        noteSales
        noteFollowUp
        noteClient
        instances {
          id
          name
          resultTester {
            ...SalesTask
          }
        }
      }
    }
  }
  ${SalesTaskFragmentDoc}
`;

export function useSalesQuery(options?: Omit<Urql.UseQueryArgs<SalesQueryVariables>, "query">) {
  return Urql.useQuery<SalesQuery>({ query: SalesDocument, ...options });
}
export const UpcomingTestsDocument = gql`
  query upcomingTests {
    upcomingTests {
      id
      date
      submittedOn
      site {
        id
        name
      }
    }
  }
`;

export function useUpcomingTestsQuery(
  options?: Omit<Urql.UseQueryArgs<UpcomingTestsQueryVariables>, "query">,
) {
  return Urql.useQuery<UpcomingTestsQuery>({ query: UpcomingTestsDocument, ...options });
}
export const PreviousTestRunsDocument = gql`
  query previousTestRuns {
    previousTestRuns {
      id
      date
      submittedOn
      site {
        id
        name
      }
    }
  }
`;

export function usePreviousTestRunsQuery(
  options?: Omit<Urql.UseQueryArgs<PreviousTestRunsQueryVariables>, "query">,
) {
  return Urql.useQuery<PreviousTestRunsQuery>({ query: PreviousTestRunsDocument, ...options });
}
export const SiteDocument = gql`
  query site($id: ID!) {
    getSite(id: $id) {
      id
      name
      address
      testableObjects {
        id
        type
        description1
        description2
        centralAddress
        cardAddress
        loopAddress
        outputAddress
        physicalAddress
        logicalAddress
      }
      testRuns {
        id
        date
        submittedOn
      }
    }
  }
`;

export function useSiteQuery(options: Omit<Urql.UseQueryArgs<SiteQueryVariables>, "query">) {
  return Urql.useQuery<SiteQuery>({ query: SiteDocument, ...options });
}
export const OutstandingIssuesDocument = gql`
  query outstandingIssues($site: ID!) {
    outstandingIssues(site: $site) {
      id
      name
      group
      object {
        id
        type
        description1
        description2
        centralAddress
        cardAddress
        loopAddress
        outputAddress
        physicalAddress
        logicalAddress
      }
      resultTester {
        id
        state
        comment
        recommendation
        testFailedStatus
      }
      resultSystem {
        id
        state
      }
    }
  }
`;

export function useOutstandingIssuesQuery(
  options: Omit<Urql.UseQueryArgs<OutstandingIssuesQueryVariables>, "query">,
) {
  return Urql.useQuery<OutstandingIssuesQuery>({ query: OutstandingIssuesDocument, ...options });
}
export const GetTestNotesDocument = gql`
  query GetTestNotes($id: ID!) {
    getTestProtocol(id: $id) {
      id
      noteSales
      noteFollowUp
      noteClient
      noteActions
    }
  }
`;

export function useGetTestNotesQuery(
  options: Omit<Urql.UseQueryArgs<GetTestNotesQueryVariables>, "query">,
) {
  return Urql.useQuery<GetTestNotesQuery>({ query: GetTestNotesDocument, ...options });
}
export const GetTestProtocolDocument = gql`
  query GetTestProtocol($id: ID!) {
    getTestProtocol(id: $id) {
      ...TestProtocol
    }
  }
  ${TestProtocolFragmentDoc}
`;

export function useGetTestProtocolQuery(
  options: Omit<Urql.UseQueryArgs<GetTestProtocolQueryVariables>, "query">,
) {
  return Urql.useQuery<GetTestProtocolQuery>({ query: GetTestProtocolDocument, ...options });
}
export const GetSharedReportDocument = gql`
  query GetSharedReport($id: ID!) {
    getSharedTestRun(sharedId: $id) {
      ...TestProtocol
    }
  }
  ${TestProtocolFragmentDoc}
`;

export function useGetSharedReportQuery(
  options: Omit<Urql.UseQueryArgs<GetSharedReportQueryVariables>, "query">,
) {
  return Urql.useQuery<GetSharedReportQuery>({ query: GetSharedReportDocument, ...options });
}
export const SubmitNotesToTestRunDocument = gql`
  mutation SubmitNotesToTestRun(
    $testRun: ID!
    $noteSales: String
    $noteFollowUp: String
    $noteClient: String
    $noteActions: String
  ) {
    submitNotesToTestRun(
      testRun: $testRun
      noteSales: $noteSales
      noteFollowUp: $noteFollowUp
      noteClient: $noteClient
      noteActions: $noteActions
    ) {
      id
      noteSales
      noteFollowUp
      noteClient
      noteActions
    }
  }
`;

export function useSubmitNotesToTestRunMutation() {
  return Urql.useMutation<SubmitNotesToTestRunMutation, SubmitNotesToTestRunMutationVariables>(
    SubmitNotesToTestRunDocument,
  );
}
export const SubmitTestRunDocument = gql`
  mutation SubmitTestRun($testRun: ID!) {
    submitTestRun(testRun: $testRun) {
      data {
        id
      }
      __typename
      error {
        ... on TestRunSubmissionAlreadyDone {
          message
        }
        ... on TestRunSubmissionIncomplete {
          message
          missing {
            id
          }
        }
      }
    }
  }
`;

export function useSubmitTestRunMutation() {
  return Urql.useMutation<SubmitTestRunMutation, SubmitTestRunMutationVariables>(
    SubmitTestRunDocument,
  );
}
export const SubmitTestResultDocument = gql`
  mutation SubmitTestResult(
    $result: TesterResult!
    $testInstance: ID!
    $comment: String
    $recommendation: String
    $testFailedStatus: TestFailedStatus
    $includeInReport: Boolean
    $actionTakenByTester: Boolean
  ) {
    submitTestResult(
      result: $result
      testInstance: $testInstance
      comment: $comment
      recommendation: $recommendation
      testFailedStatus: $testFailedStatus
      includeInReport: $includeInReport
      actionTakenByTester: $actionTakenByTester
    ) {
      id
      resultTester {
        id
        state
        comment
        recommendation
        testFailedStatus
        includeInReport
        actionTakenByTester
        photos {
          id
          thumbnailUrl
        }
      }
    }
  }
`;

export function useSubmitTestResultMutation() {
  return Urql.useMutation<SubmitTestResultMutation, SubmitTestResultMutationVariables>(
    SubmitTestResultDocument,
  );
}
