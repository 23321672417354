import styled from "@emotion/styled";
import { Grid } from "@mui/material";

export const RootStyle = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",

  height: "100vh",
  width: "100vw",

  backgroundColor: "#DEE5E6",
  backgroundImage: "url(/organic_shape.png)",
  backgroundPosition: "top right",
  backgroundRepeat: "no-repeat",

  padding: "50px 20px 10px",
  "@media (max-width: 500px)": {
    padding: "10px 20px",
  },
  backgroundSize: "500px 300px",
}));

export const MainStyle = styled(Grid)(({ theme }) => ({
  flex: 1,

  display: "flex",
  flexDirection: "column",

  height: "100%",
  overflow: "hidden",

  borderRadius: 10,

  backgroundColor: "white",
}));
