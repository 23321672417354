import * as React from "react";
import Head from "next/head";
import { AppProps } from "next/app";

import { createClient, Provider, dedupExchange, errorExchange } from "urql";
import { cacheExchange, ScalarObject } from "@urql/exchange-graphcache";
import { multipartFetchExchange } from "@urql/exchange-multipart-fetch";

import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { CacheProvider, EmotionCache } from "@emotion/react";
import { theme } from "../theme";
import createEmotionCache from "../utils/emotionCache";

import { RootStyle } from "../layouts/AppLayout/styles";
import { GetTestProtocolDocument, MutationAddObjectToTestRunArgs } from "../generated/api-client";
import { captureException } from "@sentry/minimal";
import { Severity } from "@sentry/types";
import GlobalStyles from "../theme/globalStyles";

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();
interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

export default function MyApp(props: MyAppProps) {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;

  const client = createClient({
    url: "/api/graphql",
    exchanges: [
      dedupExchange,
      cacheExchange({
        updates: {
          Mutation: {
            addObjectToTestRun(result, args: MutationAddObjectToTestRunArgs, cache, _info) {
              const query = GetTestProtocolDocument;
              cache.updateQuery({ query, variables: { id: args.testRun } }, (data) => {
                try {
                  ((data!.getTestProtocol as any).instances as ScalarObject).push(
                    result.addObjectToTestRun,
                  );
                  return data;
                } catch (e) {
                  console.warn(
                    `Could not update cache to insert new object, invalidating cache`,
                    e,
                  );
                  cache.invalidate({
                    __typename: "TestRunInstance",
                    id: args.testRun,
                  });
                  return null;
                }
              });
            },
          },
        },
      }),
      errorExchange({
        onError(error, op) {
          if (error.networkError) return;
          captureException(error, {
            level: Severity.Error,
            tags: {
              area: "graphql",
              kind: op.kind,
            },
            extra: {
              graphQLErrors: error.graphQLErrors,
              query: op.query,
              variables: op.variables,
              requestKey: op.key,
            },
          });
          console.error(error);
        },
      }),
      multipartFetchExchange,
    ],
  });

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <title>Diamond Testing</title>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <link rel="manifest" href="/manifest.webmanifest" />
      </Head>
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <GlobalStyles />
        <Provider value={client}>
          <RootStyle>
            <Component {...pageProps} />
          </RootStyle>
        </Provider>
      </ThemeProvider>
    </CacheProvider>
  );
}
