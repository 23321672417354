import { withStyles } from "@mui/styles";

const GlobalStyles = withStyles(() => ({
  "@global": {
    html: {
      "@media print": {
        fontSize: "12px",
      },
    },
  },
}))(() => null);

export default GlobalStyles;
